.drag-item {
  width: 10rem;
  height: 3rem;
  
  background-color: #29e;
  color: white;

  border-radius: 0.25em;
  padding: 0.25rem;

  -webkit-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.put-behind {
  z-index: -1;
}

#outer-dropzone {
  /* height: 300px; */
}

#inner-dropzone {
  /* height: 80px; */
}

.dropzone {
  /* background-color: #ccc; */
  background-color: transparent;
  border: dashed 4px transparent;
  border-radius: 4px;
  /* margin: 10px auto 30px;
  padding: 10px; */
  transition: background-color 0.3s;
}

.drop-active {
  border-color: #aaa;
}

.drop-target {
  /* background-color: rgb(34, 238, 129);
  opacity: 0.5; */
  border-color: #fff;
  /* border-style: solid; */
}

.drag-drop {
  display: inline-block;
  min-width: 40px;
  padding: 2em 0.5em;

  color: #fff;
  background-color: rgb(238, 34, 34);
  border: solid 2px #fff;

  -webkit-transform: translate(0px, 0px);
  transform: translate(0px, 0px);

  transition: background-color 0.3s;
}

.drag-drop.can-drop {
  color: #000;
  background-color: #4e4;
}

.drag-item.can-drop {
  color: #000;
  background-color: rgb(221, 236, 241);
  border: solid 2px rgb(46, 114, 239);
  word-wrap: break-word;
  font-size: 12px;
}