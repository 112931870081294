.text-dec-none{
    text-decoration: none;
    color:black;
/*    text-align:center;*/
    /*font-size:20px;*/
   text-align:center;

}

.request-type {
    text-decoration: none;
    color: #2196f3;
    text-align: center
}
.text-dec-none:hover {
    text-decoration: underline;
    color:blue
}

.text-eclipse {
    width: 10rem;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.w-100{
    width:100%
}
.text-center{
    text-align:center !important
}
.text-left{
    text-align:left
}

.status-i {
    width:100%;
    text-align:center;
}

.center {
    width: fit-content;
    margin: auto;
}
.text-normal {
    text-transform: inherit;
    text-align:left;
    padding:0px !important;
}



.status-i:hover{
    background-color:transparent !important;
}

.pointer{
    cursor:pointer;
}

.back-btn{
    font-size:2em !important;
    cursor: pointer;
}

.pl-25{
    padding-left: 25px !important
}

.bold{
    font-weight : bold !important
}

.sort-icon{
    height:0.6em !important
}