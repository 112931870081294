/* montserrat-100 */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100;
    src: url('Montserrat-Thin.ttf') format("truetype");
}
/* montserrat-100-italic */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 100;
    src: url('Montserrat-ThinItalic.ttf') format("truetype");
}

/* montserrat-200 */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
    src: url('Montserrat-ExtraLight.ttf') format("truetype");
}
/* montserrat-200-italic */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 200;
    src: url('Montserrat-ExtraLightItalic.ttf') format("truetype");
}

/* montserrat-300 */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: url('Montserrat-Light.ttf') format("truetype");
}
/* montserrat-300-italic */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 300;
    src: url('Montserrat-LightItalic.ttf') format("truetype");
}

/* montserrat-400 */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('Montserrat-Regular.ttf') format("truetype");
}
/* montserrat-400-italic */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 400;
    src: url('Montserrat-Italic.ttf') format("truetype");
}

/* montserrat-500 */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('Montserrat-Medium.ttf') format("truetype");
}
/* montserrat-500-italic */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 500;
    src: url('Montserrat-MediumItalic.ttf') format("truetype");
}

/* montserrat-600 */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('Montserrat-SemiBold.ttf') format("truetype");
}
/* montserrat-600-italic */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 600;
    src: url('Montserrat-SemiBoldItalic.ttf') format("truetype");
}

/* montserrat-700 */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('Montserrat-Bold.ttf') format("truetype");
}
/* montserrat-700-italic */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 700;
    src: url('Montserrat-BoldItalic.ttf') format("truetype");
}

/* montserrat-800 */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: url('Montserrat-ExtraBold.ttf') format("truetype");
}
/* montserrat-800-italic */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 800;
    src: url('Montserrat-ExtraBoldItalic.ttf') format("truetype");
}

/* montserrat-900 */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    src: url('Montserrat-Black.ttf') format("truetype");
}
/* montserrat-900-italic */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 900;
    src: url('Montserrat-BlackItalic.ttf') format("truetype");
}